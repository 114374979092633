//create tailwind menu
import { useState } from "react";
const Menu = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 150) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  });

  const closeNav =()=>{
    setNavbarOpen(false);
  }
  return (
    <>
      <nav
        className={
          "fixed top-0 left-0 flex flex-wrap p-1 mb-3 z-10 w-full" +
          (navbar ? " bg-slate-700" : " bg-slate-700")
        }
      >
        <div className="container  mx-auto flex flex-wrap">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              src="#"
              className="flex title-font font-medium items-center text-gray-900  text-white no-underline"
            >
              <img
                src={process.env.PUBLIC_URL + "/img/logo.png"}
                width="50px"
              />
              <span className="ml-5 text-xl text-white no-underline">
                DABBAWALA
              </span>
            </a>
            <button
              className="text-white cursor-pointer text-xl leading-none px-2 m-2 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {!navbarOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center justify-items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none  my-auto">
              <li className="nav-item">
                <a onClick={closeNav}
                  href="#"
                  className="px-3 py-2 flex items-center text-2xs uppercase font-bold leading-snug text-white hover:opacity-75 no-underline"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a onClick={closeNav}
                  className="px-3 py-2 flex items-center text-2xs uppercase font-bold leading-snug text-white hover:opacity-75  no-underline"
                  href="#about"
                >
                  ABOUT
                </a>
              </li>
              <li className="nav-item">
                <a onClick={closeNav}
                  className="px-3 py-2 flex items-center text-2xs uppercase font-bold leading-snug text-white hover:opacity-75  no-underline"
                  href="#bap"
                >
                  BE A PARTNER
                </a>
              </li>
              <li className="nav-item">
                <a onClick={closeNav}
                  className="px-3 py-2 flex items-center text-2xs uppercase font-bold leading-snug text-white hover:opacity-75  no-underline"
                  href="#franchise"
                >
                  FRANCHISE
                </a>
              </li>
              <li className="nav-item">
                <a onClick={closeNav}
                  className="px-3 py-2 flex items-center text-2xs uppercase font-bold leading-snug text-white hover:opacity-75 no-underline"
                  href="#contact"
                >
                  CONTACT
                </a>
              </li>
              <li className="nav-item">
                <a className="no-underline" onClick={closeNav} href="https://www.dabbawala.live/app/">
                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>Download</span>
                </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );

  // return (
  //   <header className="text-gray-600 body-font bg-slate-700">
  //     <div className="container mx-auto flex flex-wrap p-2 flex-col md:flex-row items-center">
  //       <a className="flex title-font font-medium items-center text-gray-900  text-white no-underline">
  //         <img src="https://dabbawala.live/imgs/nav_logo.png" width="50px" />
  //         <span className="ml-5 text-xl text-white no-underline">DABBAWALA</span>
  //       </a>
  //       <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
  //         <a className="mr-5 text-white no-underline hover:text-gray-900">HOME</a>
  //         <a className="mr-5 text-white no-underline hover:text-gray-900">ABOUT</a>
  //         <a className="mr-5 text-white no-underline hover:text-gray-900">BE A PARTNER</a>
  //         <a className="mr-5 text-white no-underline hover:text-gray-900">FRANCHISE</a>
  //         <a className="mr-5 text-white no-underline hover:text-gray-900">CONTACT</a>
  //       </nav>

  //     </div>
  //   </header>
  // );
};
export default Menu;
