const About = () => {
  return (
    <section className="text-gray-600 body-font" id="about">
      <div className="container px-5 pt-24 mx-auto ">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900" style={{fontFamily:"'Goblin One', cursive"}}>
            ABOUT US
          </h1>
          <div className="h-1 w-full bg-indigo-500 rounded mb-4"></div>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
 
          Dabbawala helps you find and order food from wherever you are. <br/>how
            it works ? you type in an address, we tell you the restaurants that
            deliver to that local as well as showing you droves of pickup
            restaurants near you. want to be more specific ? <br />search by cuisine,
            restaurant name or menu item. we'll filter your results accordingly.
            when you find what you're looking for, you can  place your order
            online, free of charge and we also give you access
            to coupons, special deals and 24/7 customer care team that tracks
            each order and makes sure you get exactly what you wanted.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
