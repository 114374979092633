const DownApp = ()=>{
    return (<div className="flex flex-wrap  text-center my-5 mx-1">
        <h2 className="w-full text-center  sm:mx-auto my-5 sm:w-full " style={{fontFamily:"'Goblin One', cursive"}}>Get The Dabbawala App</h2>
        
         
        <div className="w-full text-center sm:w-1/2 font-lg" >
            <a href="https://www.dabbawala.live/app/"> <img className="w-64  mx-auto text-center mx-5 sm:mx-1 my-2" src={process.env.PUBLIC_URL +"/img/appstore.png"}/>
            </a>

        </div>
        
       <div className="w-full text-center sm:w-1/2">
       <a href="https://www.dabbawala.live/app/">
       <img className="w-64 text-center mx-5 sm:mx-1 mx-auto my-2" src={process.env.PUBLIC_URL +"/img/playstore.png"}/>
       </a>
       </div>
       
        
    </div>);
};

export default DownApp;