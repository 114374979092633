import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
const Imgcor = () => {
  return (
    <Carousel className="mt-16">
      {/* <Carousel.Item>
        <video
          muted
          loop
          autoPlay
          className="d-block w-full h-fit"
          src={process.env.PUBLIC_URL + "/video/intro.webm"}
        ></video>
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item> */}
      <Carousel.Item>
        <img
          className="d-block  w-full"
          src={process.env.PUBLIC_URL + "/img/img1.png"}
          alt="Second slide"
        />

        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + "/img/img2.png"}
          alt="Third slide"
        />

        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + "/img/img3.png"}
          alt="Third slide"
        />

        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + "/img/img4.png"}
          alt="Third slide"
        />

        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Imgcor;
