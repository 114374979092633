const City =()=>{
    return(
        <section className="text-gray-600 body-font">
  <div className="container px-5 pt-12 mx-auto">
    <div className="flex flex-col text-center w-full mb-2">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900" style={{fontFamily:"'Goblin One', cursive"}}>We Are Available In</h1>
      <div className="h-1 w-full bg-indigo-500 rounded mb-4"></div>
      
    </div>
    <div className="flex flex-wrap -m-4">
      <div className="lg:w-1/3 sm:w-1/2 p-4 mx-auto">
        <div className="flex relative w-full bg-slate-500 rounded-2xl">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/Airoli.png"}/>
        </div>
      </div>


      
      <div className="lg:w-1/3 sm:w-1/2 p-4  mx-auto">
        <div className="flex relative w-full  bg-slate-500 rounded-2xl">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/vapi.png"}/>
        </div>
      </div>

      <div className="lg:w-1/3 sm:w-1/2 p-4  mx-auto">
        <div className="flex relative w-full  bg-slate-500 rounded-2xl">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/Thane.png"}/>
        </div>
      </div>

      
      <div className="lg:w-1/3 sm:w-1/2 p-4  mx-auto">
        <div className="flex relative w-full  bg-slate-500 rounded-2xl">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/SILVASSA.png"}/>
        </div>
      </div>

      <div className="lg:w-1/3 sm:w-1/2 p-4  mx-auto">
        <div className="flex relative w-full  bg-slate-500 rounded-2xl p-2">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/kalaburagi.png"}/>
        </div>
      </div>

      <div className="lg:w-1/3 sm:w-1/2 p-4  mx-auto">
        <div className="flex relative w-full  bg-slate-500 rounded-2xl p-2">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/Navi_Mumbai.png"}/>
        </div>
      </div>
      
      <div className="lg:w-1/3 sm:w-1/2 p-4  mx-auto">
        <div className="flex relative w-full  bg-slate-500 rounded-2xl ">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/Daman1.png"}/>
        </div>
      </div>


      <div className="lg:w-1/3 sm:w-1/2 p-4  mx-auto ">
        <div className="flex relative w-full  bg-slate-500 rounded-2xl ">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/Hubli1.png"}/>
        </div>
      </div>

      

      <div className="lg:w-1/3 sm:w-1/2 p-4  mx-auto">
        <div className="flex relative w-full  bg-slate-500 rounded-2xl p-2">
          <img alt="gallery" className="w-full  object-cover object-center" src={process.env.PUBLIC_URL+"/img/Kanpur1.png"}/>
        </div>
      </div>



      




     
     
     
      
    </div>
  </div>
</section>
    );

};
export default City;