const Beap = () => {
  return (
    <>
      <section className="text-gray-600 body-font" id="franchise">
        <div className="container px-5 py-24 mx-auto">
          <h1
            className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 text-center"
            style={{ fontFamily: "'Goblin One', cursive" }}
          >
            Become A Franchise
          </h1>
          <div className="h-1 w-full bg-indigo-500 rounded mb-8"></div>

          <div className="flex flex-wrap -m-4">
            <div className="xl:w-1/4 md:w-1/2 p-4 ">
              <div className="h-full p-6 rounded-lg" style={{ background: "#4d668b" }}>
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={process.env.PUBLIC_URL + "/img/graphup.png"}
                  alt="content"
                />

                <h2 className="text-lg text-white font-medium title-font mb-4">
                  FAST BRAND EXPANSION
                </h2>
                <p className="leading-relaxed text-base text-white">
                  Fully automated business model, standard operating procedures,
                  state of the art technology make it ideal business model{" "}
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="h-full p-6 rounded-lg "
                style={{ background: "#4d668b" }}
              >
                <img
                  className="h-40 rounded w-full object-center mb-6"
                  src={process.env.PUBLIC_URL + "/img/handshake.png"}
                  alt="content"
                />

                <h2 className="text-lg text-white font-medium title-font mb-4">
                  LOW INVESTMENT
                </h2>
                <p className="leading-relaxed text-base text-white">
                  No physical inventory, no physical infrastructure, no office
                  rent, no salary to pay makes it pure earning platform{" "}
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div className="h-full p-6 rounded-lg" style={{ background: "#4d668b" }}>
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={process.env.PUBLIC_URL + "/img/graphup.png"}
                  alt="content"
                />

                <h2 className="text-lg text-white font-medium title-font mb-4">
                  READY INFRASTRUCTURE
                </h2>
                <p className="leading-relaxed text-base text-white">
                  All licenses ready and operational enablement complete and
                  tried tested aggragator model to concept restaurant to hungry
                  people{" "}
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div className="h-full p-6 rounded-lg" style={{ background: "#4d668b" }}>
                <img
                  className="h-40 rounded w-full  object-center mb-6"
                  src={process.env.PUBLIC_URL + "/img/handshake.png"}
                  alt="content"
                />

                <h2 className="text-lg text-white font-medium title-font mb-4">
                  BETTER RETURNS
                </h2>
                <p className="leading-relaxed text-base text-white">
                  Return on Investment (R.O.I) give you more than 200% and break-even of less than six months result in great returns{" "}
                </p>
              </div>
            </div>
            <a href="https://www.dabbawala.live/contact/" className="no-underline w-full">
              <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Connect with us now
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default Beap;
