const ConFrom = ()=>{
    return (
        <div className="flex w-full mb-36 pt-24" id="contact">
        <form method="post" action="https://dabbawala.live/register/send.php" className="w-80 mx-auto p-3 rounded-md" style={{background:"#4d668b"}}>
            <h2 className="text-center text-white" style={{fontFamily:"'Goblin One', cursive"}}>Contact Us</h2>
        <div className="mb-6">
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Your email</label>
          <input type="email" id="email" name="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@gmail.com" required />
        </div>
        <div className="mb-6">
          <label htmlFor="post-code" className="block mb-2 text-sm font-medium text-white">Your Post Code</label>
          <input id="post-code" type="number" name="post-code" maxLength={6} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
        </div>
       
        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
      </form>
      </div>
    );
};
export default ConFrom;