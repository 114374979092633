const Footer = () => {
  return (
    <footer
      className="text-gray-600 body-font"
      style={{ background: "#232F3E" }}
    >
      <div className="container px-5  mx-auto pt-5">
        <div className="flex flex-wrap md:text-left text-center order-first">
          <div className="lg:w-1/3 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 text-white">
              GET TO KNOW US
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                  href="#about"
                >
                  About dabbawala
                </a>
              </li>
              <li>
                <a
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                  href="https://www.dabbawala.live/app/"
                >
                  Our App
                </a>
              </li>
              <li>
                <a
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                  href="http://dabbawaladeliveries.blogspot.com/"
                >
                  Our Blog
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 text-white">
              PARTNER WITH US
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="https://www.dabbawala.live/register/"
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                >
                  For Restaurant
                </a>
              </li>
              <li>
                <a
                  href="https://www.dabbawala.live/12317820/"
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                >
                  For Delivery Partner
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3 text-white">
              HELP
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="https://dabbawala.live/help"
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                >
                  Guide
                </a>
              </li>
              <li>
                <a
                  href="https://dabbawala.live/faq"
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="https://dabbawala.live/consumer-terms/"
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                >
                  TERMS
                </a>
              </li>
              <li>
                <a
                  href="https://dabbawala.live/privacy/"
                  className="text-gray-400 hover:text-white no-underline cursor-pointer"
                >
                  PRIVACY
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div style={{ background: "#131A22" }}>
        <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
          <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900 no-underline text-white">
            <img src={process.env.PUBLIC_URL + "/img/logo.png"} width="50px" />
            <span className="ml-3 text-xl">DABBAWALA</span>
          </a>
          <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
            © 2022 Dabbawala —
            <a rel="noopener noreferrer" className="text-gray-600 ml-1">
              @Smart Technology
            </a>
          </p>
         
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
     
            
       
        <a className="text-gray-500 fa fa-facebook text-2xl hover:text-blue-500 no-underline" href="https://www.facebook.com/DabbawalaDeliveries/">
          
        </a>
        <a className="ml-3 text-gray-500 fa fa-twitter hover:text-blue-500 text-2xl no-underline" href="https://twitter.com/DABBAWALADELIV1">
         
        </a>
        <a className="ml-3 text-gray-500 fa fa-instagram hover:text-rose-700 text-2xl no-underline" href="https://www.instagram.com/dabbawaladeliveries/">
          
        </a>
        <a className="ml-3 text-gray-500 fa fa-linkedin text-2xl hover:text-blue-500 no-underline" href="https://www.linkedin.com/company/dabbawala-deliveries">
         
        </a>

        <a className="ml-3 text-gray-500 fa fa-youtube hover:text-red-500 text-2xl no-underline" href="https://www.youtube.com/channel/UCG-PJOVNHxELWrOt3tBTzAg">
         
        </a>
      </span>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
