const ImgCard = () => {
  return (
    <section className="text-gray-600 body-font" id="bap">
      
      <div className="container mx-auto px-5 py-24">
      <h1
        className="text-center sm:text-3xl text-2xl font-medium title-font text-gray-900 text-center"
        style={{ fontFamily: "'Goblin One', cursive" }}
      >
        Be A Partner
        <div className="h-1 w-full bg-indigo-500 rounded mb-4"></div>
      </h1>
      
      
        <div className="flex flex-wrap -m-4 ">
       
          <div className="p-4 lg:w-1/3 sm:w-1/2 ">
            <div
              className="h-full bg-gray-100 bg-opacity-75 px-4 pt-4 pb-16 rounded-lg overflow-hidden text-center relative shadow-2xl"
              style={{ background: "#4d668b" }}
            >
              <img
                className="rounded-lg my-2"
                alt="error"
                src={process.env.PUBLIC_URL + "/img/restaurant.jpg"}
              />
              <h1
                className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 text-white"
                style={{ fontFamily: "'Goblin One', cursive" }}
              >
                RESTARUNT PARTNER
              </h1>
              <p className="leading-relaxed mb-3 text-white">
              When you onboard with dabbawala as restaurant, you get instant
                access to millions of hungry customers eager to order online
                from local near by restaurants via dabbawala. reach new
                customers and get more delivery and pickup orders today - it's
                cost free ( yes, we do not take any on-boarding fees ) and easy to
                get started{" "}
              </p>
              <a href="https://www.dabbawala.live/register/"
                className="text-indigo-500 inline-flex items-center no-underline"
                style={{ color: "#e0e3e4" }}
              >
                Learn More
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 lg:w-1/3  sm:w-1/2">
            <div
              className="h-full bg-gray-100 bg-opacity-75 px-4 pt-4 pb-16 rounded-lg overflow-hidden text-center relative shadow-xl"
              style={{ background: "#4d668b" }}
            >
              <img
                className="rounded-lg my-2"
                alt="error"
                src={process.env.PUBLIC_URL + "/img/delivery.jpg"}
              />
              <h1
                className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 text-white"
                style={{ fontFamily: "'Goblin One', cursive" }}
              >
                DELIVERY PARTNER
              </h1>
              <p className="leading-relaxed mb-3 text-white">
              Join our delivery fleet today and start earning weekly! work on
                your preffered hours ( part-time or full-time ) joining
                dabbawala is as easy as ordering food from us
              </p>
              <a href="https://www.dabbawala.live/12317820/"
                className="text-indigo-500 inline-flex items-center  no-underline"
                style={{ color: "#e0e3e4" }}
              >
                Learn More
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 lg:w-1/3  sm:w-1/2">
            <div
              className="h-full bg-gray-100 bg-opacity-75 px-4 pt-4 pb-16 rounded-lg overflow-hidden text-center relative shadow-xl"
              style={{ background: "#4d668b" }}
            >
              <img
                className="rounded-lg my-2"
                alt="error"
                src={process.env.PUBLIC_URL + "/img/partner.jpg"}
              />
              <h1
                className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3 text-white"
                style={{ fontFamily: "'Goblin One', cursive" }}
              >
                AFFILATE PARTNER
              </h1>
              <p className="leading-relaxed mb-3 text-white">
              Dabbawala is the leader in food ordering and delivery, a.k.a.
                the best spot to find mouth-watering dishes, with over 3,000
                restaurants on the app, customers have a tons of options - which
                means you have a tons of opportunities to make money.
              </p>
              <a href="https://www.dabbawala.live/contact/"
                className="text-indigo-500 inline-flex items-center  no-underline"
                style={{ color: "#e0e3e4" }}
              >
                Learn More
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImgCard;
