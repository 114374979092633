import { useEffect, useState } from "react";
import About from "./component/about";
import Beap from "./component/beap";
import City from "./component/city";
import DownApp from "./component/DownApp";
import Footer from "./component/footer";
import ConFrom from "./component/form";
import Imgcor from "./component/imgCar";
import ImgCard from "./component/imgcard";
import Menu from "./component/menu.js";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      {loading ? (
        //write a loader

        <div
          className="loading"
          style={{
            width: "100%",
            height: "100vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div
            className="spinner-border animate-spin block w-32 h-32 border-4 rounded-full text-purple-500"
            role="status"
          >
      
          </div>
        </div>
      ) : (
        <main style={{ background: "#f3f4f9" }}>
          <Menu />
          <Imgcor />
          <City />
          <About />
          <ImgCard />
          <Beap />
          <ConFrom />
          <DownApp />
          <Footer />
        </main>
      )}
    </>
  );
}

export default App;
